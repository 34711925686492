<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" type="flex" justify="begin">
      <el-col :lg="12" :md="12" :sm="16" :xs="16" :offset="0">
      <el-input width="50" placeholder="Buscar" size="mini" v-model="searchForm.query" v-on:change="handleSearch()" clearable></el-input>
      </el-col>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="handleSearchFilter"
        type="primary"
        icon="el-icon-search"
        size="mini"
      >Buscar</el-button>
      <el-button
        size="mini"
        @click.stop.prevent="handleClean"
        type="warning"
        icon="el-icon-delete"
      >Limpiar Filtros</el-button>
      <el-button
        size="mini"
        @click.stop.prevent="handleCreate"
        type="success"
        disabled
      >Nuevo</el-button>
    </el-row>
  <template>
  </template>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      style="width: 100%"
      size="mini"
    >
      <!-- <el-table-column type="expand">
        <template slot-scope="props">
          <p>Mensaje: {{ props.row.description }}</p>
        </template>
      </el-table-column>-->
      <el-table-column label="Imagen" align="center">
        <template slot-scope="scope">
        <el-image :src="$urlGlobalApi + scope.row.image" class="size-image-small">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
        </el-image>
        </template>
      </el-table-column>
      <el-table-column label="Título" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Orden" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.order }}</span>
        </template>
      </el-table-column>
       <el-table-column label="Publicado" align="center">
          <div slot-scope="scope" v-if="scope.row.status=== true">
            Publicado
        </div>
        <div v-else>
             No publicado
        </div>
      </el-table-column>
      <el-table-column align="center" label="Fecha de Actualización" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.updated_at | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operaciones"  align="center" width="200">
        <template slot-scope="scope">
          <el-button
          size="mini"
          round
          type="primary"
          @click="handleEdit(scope.$index, scope.row)"
            >Editar</el-button
          >
          <el-button
           @click.stop.prevent="handleDisable(scope.$index, scope.row)"
            size="mini"
            type="danger"
            round
            :disabled="scope.row.status? false : true"
            >Desactivar</el-button
          >
          <!-- @click.native.prevent="handleDelete(scope.$index, scope.row)"-->

        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { destroySections, getPage, search } from '@/api/sections'
export default {
  data () {
    return {
      searchForm: {
        query: '',
        title: '',
        image: ''
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      users: null,
      list: null,
      loading: false,
      loadingDelete: false,
      loadingPublish: false,
      isVisibleCollection: false,
      formEditCollection: {}
    }
  },
  created () {
    this.handleSearch()
    this.handleSearchFilter()
  },
  methods: {
    async handleSearch () {
      this.loading = true
      await search(this.searchForm)
        .then(response => {
          console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleCreate () {
      this.$router.push({ name: 'sections.create' })
    },
    handleEdit (index, row) {
      console.log(row._id)
      this.$router.push({ name: 'sections.edit', params: { _id: row._id } })
    },
    handleDisable (index, row) {
      this.loadingDelete = true
      this.$confirm('Ya no se mostrará en la app. Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroySections(row._id)
            .then(response => {
              // let res = response.data
              this.$message({
                showClose: true,
                message: 'Se eliminó correctamente!',
                type: 'success'
              })
              this.list = response.data.data
              this.totalPages = response.data.total
              // this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then(response => {
          console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.status = ''
      this.handleSearchFilter()
    }
  }
}
</script>
